//react
import React, {ReactNode, useEffect, useState, FC} from "react";

import classNames from "classnames";
import {useSelector} from "react-redux";
import Link from "next/link";
import {FormattedMessage} from "react-intl";
import {useRouter} from "next/router";
import {rightUrl} from "../../services/utils";
import {IState} from "../../types/state";
import {schemaLink} from "./NavLinks/types";
import LinksList from "./NavLinks/LinksList";
import {IProps} from "../../types/homePageTypes";
import dynamic from "next/dynamic";
import {useSelect} from "@mui/base";

const HotelDepartments = dynamic(() => import('./Hotel_Departments'))

function NavLinks({pageProps, layout}: { pageProps?: IProps, layout: string }) {
    let departments: JSX.Element | null = null;
    const router = useRouter();
    const menuList = useSelector((state: IState) => state.general.menuList);
    const locale = useSelector((state: IState) => state.locale.code);
    const isBlogActiveRedux = Boolean(+useSelector((state:IState) => state.general?.coreConfigs?.theme_blog_active?.value))
    const isTackingActiveRedux = Boolean(+useSelector((state:IState) => state.general?.coreConfigs?.sales_tracking_tracking_active?.value))
    let realPath = router.locale !== 'catchAll' ? router.locale + '/' : '';

    const schemaLinks: schemaLink = {
        "@context": "https://schema.org/",
        "@type": "Menu",
        "url": "/",
        "name": "MenuItems",
        "description": "Company menu items description",
        "hasMenuSection": menuList.map((item, index) => ({
            "@type": "MenuSection",
            "position": index + 1,
            "hasMenuItem": {
                "@type": "MenuItem",
                "name": item?.name,
            },
        })),

    };

    const isBlogActive = router.asPath === "/" ? pageProps?.isBlogActive : isBlogActiveRedux
    const isTackingActive = router.asPath === "/" ? pageProps?.isTackingActive : isTackingActiveRedux
    const navLinks = router.asPath === "/" ? pageProps?.menus : menuList

    const linksList: JSX.Element[] = navLinks
        ?.filter((item: { type: string }) => item.type === "header")
        ?.map((item: any, index: number) => {
                const hasChildren = item.children && item.children.length > 0;
                const classes = classNames("nav-links__item",
                    hasChildren && "nav-links__item--with-submenu"
                );

                return (
                    <LinksList
                        key={index}
                        schemaLinks={schemaLinks}
                        realPath={realPath}
                        classes={classes}
                        locale={locale}
                        item={item}
                        hasChildren={hasChildren}
                    />
                );
            }
        );

    if (layout === "default") {
        departments = (
            <div className="nav-panel__departments">
                <HotelDepartments/>
            </div>
        );
    }

    return (
        <div className="nav__bar">
            {departments}
            <ul className="nav-links__list">
                {linksList}
                {isTackingActive &&
                    <li className="nav-links__item">
                        <Link href={rightUrl(`/tracking`, router)} prefetch={false}>
                            <a className="navbar-link-hover">
                                <FormattedMessage
                                    //TODO this pageProps doesn't have any hasTracking key, it is undefined
                                    id="tracking"
                                    defaultMessage="Tracking Number"
                                />
                            </a>
                        </Link>
                    </li>
                }
                {isBlogActive && (
                    <li className="nav-links__item">
                        <Link href={rightUrl(`/blog`, router)} prefetch={false}>
                            <a className="navbar-link-hover">
                                <FormattedMessage id="blog" defaultMessage="Blog"/>
                            </a>
                        </Link>
                    </li>
                )}
            </ul>
        </div>
    );
}

export default NavLinks;
