import { MouseEvent } from 'react'
import languages from "../../../i18n";
import {LanguagesType} from "./types";

const handleMouseEnter = (event: MouseEvent<HTMLLIElement>, locale: keyof LanguagesType) => {
    const {direction} = languages[locale];
    const item = event.currentTarget;
    const megaMenu = item.querySelector(".nav-links__megaMenu") as HTMLElement;
    if (megaMenu) {
        const container = (megaMenu as  HTMLElement).offsetParent;
        const containerWidth = container?.getBoundingClientRect().width || 0;
        const megaMenuWidth = megaMenu.getBoundingClientRect().width;
        const itemOffsetLeft = item.offsetLeft;

        if (direction === "rtl") {
            const itemPosition =
                containerWidth -
                (itemOffsetLeft + item.getBoundingClientRect().width);

            const megaMenuPosition = Math.round(
                Math.min(itemPosition, containerWidth - megaMenuWidth)
            );

            megaMenu.style.left = "";
            megaMenu.style.right = `${megaMenuPosition}px`;
            return;
        }

        const megaMenuPosition = Math.round(
            Math.min(itemOffsetLeft, containerWidth - megaMenuWidth)
        );

        megaMenu.style.right = "";
        megaMenu.style.left = `${megaMenuPosition}px`;

    }
};
export default handleMouseEnter;