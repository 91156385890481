import React, {FC} from 'react';
import Head from "next/head";
import handleMouseEnter from "./handleMouseEnter";
import {LanguagesType} from "./types";
import Link from "next/link";
import dynamic from "next/dynamic";

const NavMenu = dynamic(() => import('./../NavMenu'));
import {ArrowRoundedDown9x6Svg} from "../../../svg";
import {linksListProps as IProps} from "./types";

const LinksList: FC<IProps> = (
    {
        schemaLinks,
        classes,
        locale,
        item,
        realPath,
        hasChildren
    }
) => {

// let rightLink = item.url_key ? item.url_key : item.slug ?  `/${realPath}page/${item.slug}`:  ""
    let rightLink = (item.url_key || item.slug && `/${realPath}page/${item.slug}`) || ""
    let target = item.url_key ? "_blank" : ""
    return (
        <React.Fragment>
            <Head>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{__html: JSON.stringify(schemaLinks)}}
                />
            </Head>
            <li
                className={classes}
                onMouseEnter={event => handleMouseEnter(event, locale as keyof LanguagesType)}
            >

                <Link
                    href={rightLink}
                    prefetch={false}
                >
                    {/*<a className="navbar-link-hover" target={target}>*/}
                    {/*    {item.name}*/}
                    {/*    {hasChildren && <ArrowRoundedDown9x6Svg className="nav-links__arrow"/>}*/}
                    {/*</a>*/}
                    {
                        item.new_tab
                            ? <a target="_blank">
                                {item.name}
                                {hasChildren && <ArrowRoundedDown9x6Svg/>}
                            </a>
                            : <a>
                                {item.name}
                                {hasChildren && <ArrowRoundedDown9x6Svg/>}
                            </a>
                    }
                </Link>
                {hasChildren && (
                    <div className="nav-links__menu">
                        <NavMenu items={item.children} realPath={realPath}/>
                    </div>
                )}
            </li>
        </React.Fragment>
    )
}

export default LinksList;
